$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1280px,
    xxxl: 1760px
);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
  xxxl: 1860px,

);
$themeColor: #000;
$primary: #5330B2;
$light-purple: #9980CA;
$yellow: #FFDA56;
$hover-purple: #441CB1;
$black: #17191A;
$line-on-black-grey: #353535;
$body: #575859;
$inut-text: #7F7F7F;
$line-grey: #E3DFD7;
$background: #FFFAF0;
$light-grey: #F9F9F9;
$white: #FFFFFF;
$success-green: #16BB4E;
$error-red: #F43D3D;
$input-text:#7F7F7F;