.privacy-container {
    margin: 3.5rem auto 5.563rem;
    width: 100%;
    padding: 0;
    @include media-breakpoint-down(lg) {
        max-width: 100%;
        padding: 3.5rem 1.5rem 6.5rem;
        margin: 0
    }
    h1 {
        @include med-32;
        color: $black;
        padding-bottom: 1.5rem;
        margin: 0;
    }
    p, li {
        font-size: 0.875rem;
        line-height: 1.313rem;
        color: #404143;
        padding-bottom: 1.5rem;
        margin: 0;
    }
    h2 {
        @include med-20;
        color: $black;
        padding-bottom: 1.5rem;
        margin: 0;
    }
    h3 {
        font-weight: 500;
        line-height: 1.5rem;
        color: $black;
        font-size: 1rem;
        padding-bottom: 0.5rem;
        margin: 0;
    }
}

.privacy-container-content {
    max-width: 47.75rem;
    @include media-breakpoint-down(lg) {
        max-width: 100%;
    }
}