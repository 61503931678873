.blog-container {
  padding: 3.5rem 0 6.5rem;
  .success-header {
    margin-bottom: 3.5rem;
  }

  @include media-breakpoint-down(lg) {
    padding: 3.5rem 1.5rem 6.5rem;
    .success-header {
      margin-bottom: 2rem;
      h2 {
        margin-bottom: 3.5rem;
      }
    }
  }
}
.blog-page {
  margin-bottom: 6rem;
  @include media-breakpoint-down(lg) {
    margin-bottom: 3.5rem;
  }
}
.pagination {
  margin: 0;
  flex-wrap: wrap;
  .page-item {
    a {
      color: $black;
    }
  }
  .page-item .page-link {
    border: none;
    margin: 0.25rem 0;
    &:hover {
      background-color: $hover-purple;
      color: $white;
      img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
          saturate(2105%) hue-rotate(354deg) brightness(117%) contrast(101%);
      }
    }
  }
  @include media-breakpoint-down(lg) {
    margin: 0.25rem 0;
  }
  li {
    font-family: Krona One, sans-serif;
    color: $black;
  }
}

.pagination-nav-right {
  transform: rotate(180deg);
}

#blog-search {
  max-width: 24rem;
  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }
  width: 100%;
  .inner-blog-search {
    gap: 1.5rem;
  }
  select {
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    padding: 0.5rem 0;
    cursor: pointer;
  }
  .form-select {
    background-image: url("../img/chevron_right.svg");
    background-repeat: no-repeat;
    background-position: right -3px center;
    background-size: 1.25rem;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $input-text;
  }
}
.blog-inner-container {
  padding: 2.75rem 0 0;
  @include media-breakpoint-down(lg) {
    padding: 2.75rem 1.5rem 0;
    margin: 0;
    max-width: 100%;
  }
}

.blog-content-typography {
  h1 {
    font-size: 2rem;
    line-height: 2.813rem;
    font-weight: 500;
    margin: 2rem 0 0.75rem;
  }
  p {
    color: $body;
    margin-bottom: 2rem;
  }
}

.blog-inner-col {
  max-width: 52.813rem;
    @include media-breakpoint-up(lg) {
      padding-right: 1rem;
    }
  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }
}
.register-card {
  background: $background;
  max-width: 18.875rem;
  width: 100%;
  border-radius: 0.125rem 3.5rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;

  p {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: $body;
  }
  a {
    font-weight: 500;
  }
}

.bar-long {
    height: 6px;
    background-color: $yellow;
    width: 0px;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
}