@mixin hover-focus($self: false) {
    @if $self {
  
        &,
        &:hover,
        &:active,
        &:focus {
            @content;
        }
    }
  
    @else {
  
        &:hover,
        &:active,
        &:focus {
            @content;
        }
    }
  }
  
  @mixin absolute-center {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;
  }
  
  @mixin line-clamp($numLines: 1) {
    overflow: hidden;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $numLines;
    -webkit-box-orient: vertical;
  }

  @mixin label-2-medium {
  
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.125rem;
  }
  @mixin med-20 {
   
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.875rem;
  }
  @mixin med-24 {
    font-weight: 500;
font-size: 1.5rem;
line-height: 2.25rem;

  }
  @mixin med-32 {
 
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.813rem;
  }
  @mixin reg-18 {
 
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.688rem;
  }