.about-container {
    padding: 3.5rem 0;
    p {
        font-size: 1.125rem;
        color: $body;
        font-weight: 500;
        line-height: 1.668rem;
        margin-bottom: 2rem;
    }
    h1 {
        margin-bottom: 1rem;
    }
    @include media-breakpoint-down(md) {
        padding: 3.5rem 1.5rem 4rem;
        margin: 0;
    }
}
.absolute-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    @include media-breakpoint-up(lg) {
         max-width: 32rem;
    }
   
    //margin: 0 auto;
}
.experience-banner {
    padding: 3.5rem 0;
    background: $background;
    p {
        font-weight: 500;
    font-size: 1.125rem;
    color: $body;
    }
    h2 {
        color: $primary;
    }
    @include media-breakpoint-up(lg) {
        .row {
            justify-content: space-around;
        }
    }
     @include media-breakpoint-down(md) {
        p {
            text-align: center;
        }
        padding: 3.5rem 1.5rem;
     }
}
.experience-col {
    @include media-breakpoint-up(lg) {
          max-width: 12rem;
    }
    @include media-breakpoint-down(md) {
            display: flex;
    flex-direction: column;
    align-items: center;
    }
  
    padding: 0;
}
.aboutus-blocks {
    padding: 4rem 0 6.5rem;
      @include media-breakpoint-down(md) {
        padding: 3.5rem 1.5rem 6.5rem;
        margin: 0;
    }
   
        .row .col-lg-6 {
              @include media-breakpoint-down(lg) {
                padding: 0;
              }
        }
        
    
    
    
        
    
}
.success-image {
    border-radius: 0px 9.063rem;
    @include media-breakpoint-down(lg) {
        max-height: 25rem;
        object-fit: fill;
        border-radius: 0px 5.5rem;
    }
}
.aboutus-hero-section {
    padding: 0 0 0 5.75rem;
@include media-breakpoint-down(lg) {
        padding: 0;
}
}
.success-description {
    @include media-breakpoint-up(lg) {
        max-width: 32.5rem;
    }
    @include media-breakpoint-down(lg) {
        margin-bottom: 3.5rem;
    }
    p {
font-family: Krona One;
font-size: 1.5rem;
line-height: 2.25rem;
color: $black;
margin: 0;
@include media-breakpoint-down(lg) {
    font-size: 1rem;
    line-height: 1.5rem;
}
    }
    img {
        margin-bottom: 2rem;
    }
}