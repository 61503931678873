#platform-pills {
  margin-bottom: 3rem;
    .nav-item {
        .nav-link {
            margin-right: 1rem;
            margin-bottom: 1rem;
            padding: 0.75rem 1.25rem;
            border-radius: 2px;
            background: $background; 
            color: $black;
       
        }
        .nav-link.active {
             background: $yellow;
             font-weight: 500;
        }


    }

 @include media-breakpoint-down(lg) {
  white-space: nowrap;
  flex-wrap: nowrap;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: block;
  li {
    display: inline-block;
  }
}
}

#pills-tabContent {
  .tab-pane > .row .col-xl-3 {

    margin-bottom: 3.5rem;
    @include media-breakpoint-down(md) {
      margin-bottom: 1.5rem;
    } 
}
}
.platform-container {
  padding: 3.5rem 0 3rem;

     @include media-breakpoint-down(md) {
      padding: 3.5rem 1.5rem 3rem;
      margin: 0;
      max-width: 100%;
     }
}
.platform-banner {
  padding-bottom: 6.5rem;
  @include media-breakpoint-down(lg) {
    padding-bottom: 0;
  }
  img {
    margin: 3.5rem 0 4.25rem;
  }
}

.platform-plan-card {
  padding: 2rem 2rem;
  //border: none;
  height: 100%;
}
.platform-plans-outer {
    .row {
      .col-xxl-3 {
        margin-bottom: 3.5rem;
      }
  }
}

.popular-banner {
  background: $yellow;
border-radius: 2px rem;
font-weight: 500;
font-size: 0.875rem;
line-height: 1.313rem;
margin-bottom: 0.5rem;
padding: 0.375rem 1rem;
max-width: 7.5rem;
}
.vertical-line {
  border-right: 1px solid $line-grey;
}

.plan-col {
    padding: 2rem;
    position: relative;
    max-width: 22.875rem;
    //adding right border to plans
     @include media-breakpoint-up(xxl) {
          &:not(:nth-child(4n)):not(:last-child) {
            border-right: 1px solid $line-grey;
          }
        }
        @include media-breakpoint-between(lg,xxl) {
          &:not(:nth-child(3n)):not(:last-child) {
            border-right: 1px solid $line-grey;
          }
        }
        @include media-breakpoint-between(md, lg) {
          &:not(:nth-child(2n)):not(:last-child) {
            border-right: 1px solid $line-grey;
          }
        }
        @include media-breakpoint-down(md) {
          &:not(:first-child):not(:last-child) {
            border-bottom: 1px solid $line-grey;
            padding-bottom: 3.5rem;
          }
          &:not(:first-child) {
             padding: 0;
          }
         
        }
         @include media-breakpoint-down(lg) {
          max-width: 100%;
         }

    .plan-col-inner {
      display: flex;
    flex-direction: column;
    justify-content: space-between;
    }

    .card-title {
      @include med-24;
  
    }
    .card-text {
      font-size: 1.125rem;
      line-height: 1.688rem;
      @include line-clamp(4);
      margin-bottom: 1.5rem;
    }
    span,sub {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 500;
    }
    h6 {
      font-size: 1.5rem;
  line-height: 2.25rem;
    }
    a {
      font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.688rem;
  text-decoration-line: underline;
  color: $primary;
  display: flex;
  justify-content: center;
  padding: 0.5rem 0 0;
    }

  
}

#popular-plan {
  border: 2px solid $light-purple;
border-radius: 0px 2rem;
//border-right: 2px solid $light-purple;
}

#plan-search {
    max-width: 18.875rem;
       position: relative;
       margin-bottom: 5rem;
  display: flex;
    button {
      background: url("../img/search_icon.svg");
      background-repeat: no-repeat;
      text-indent: -9999px;
      width: 1.5rem;
      height: 1.5rem;
      border: none;
      position: absolute;
      right: 0;
    }
}


.platform-inner-container {
  padding: 3rem 0 6.5rem;
  @include media-breakpoint-down(lg) {
    padding: 3rem 1.5rem 6.5rem;
  }
  }

  .back-link {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    font-weight: 500;
    img {
       filter: brightness(0) saturate(100%) invert(18%) sepia(71%) saturate(5150%) hue-rotate(255deg) brightness(73%) contrast(87%);
       transform: rotate(90deg);
       margin-right: 0.25rem;
    }
  }

  .row {
    .initial-platform-col {
      border-right: 1px solid $line-grey;
      padding-right: 1.5rem;
      @include media-breakpoint-down(lg) {
        border-bottom: 1px solid $line-grey;
        border-right: none;
        padding: 0 0 3.5rem;
      }
    }
    .secondary-platform-col {
      h3 {
        font-family: Krona One, sans-serif;
        font-size: 1.5rem;
line-height: 2.25rem;
      }
      padding-left: 1.5rem;
       @include media-breakpoint-down(lg) {
         padding: 3.5rem 0 0;
      }
    }
  }
  h3 {
       font-size: 1.5rem;
line-height: 2.25rem;
margin: 0 0 0.5rem;
font-weight: 500;
  }
  p {
    color: $body;
  }


.custom-platform-list {
  list-style: none;
  padding: 0;
  li::before {
  content: url('../img/list_icon_yellow.svg');
  padding-right: 1rem;
  }
  li {
    padding: 1rem 0 0;
    font-size: 1.125rem;
    color: $body;
    display: flex;
  }
}
.custom-platform-list-level-2 {
  list-style: none;
  padding: 0 0 0 2rem;
  li::before {
  content: url('../img/list_icon_purple.svg');
  padding-right: 1rem;
  }
  li {
    padding: 1rem 0 0;
    font-size: 1.125rem;
    color: $body;
    display: flex;
  }
}
.inner-heading-content {
  max-width: 52.813rem;
  margin: 2rem 0 1.5rem;
  sub {
    font-size: 1rem;
    font-family: DM Sans, sans-serif;
    line-height: 1.5rem;
    font-weight: 500;
  }
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}