.digital-first-col {
    max-width: 39.25rem;
    @include media-breakpoint-down(lg) {
        max-width: 100%;
        padding: 0;
    }
    p {
        margin: 1.5rem 0 0;
        color: $body;
        font-size: 1.125rem;
        line-height: 1.688rem;
    }
}
.digital-container {
    padding: 7.625rem 0 6.5rem;
    @include media-breakpoint-down(lg) {
        padding: 3.5rem 1.5rem 6.5rem;
        margin: 0;
    }
}
.digital-second-col {
    @include media-breakpoint-down(lg) {
        margin: 3.5rem 0
    }
}
.digital-contact-col {
    display: flex;
    align-items: center;
    margin-top: 2rem;
      button {
        max-width: 18.875rem;
        margin: 0 1.5rem 0 0;
        @include media-breakpoint-down(md) {
            margin: 0 0 1.5rem;
        }
    }
    a {
        display: contents;
    }
    @include media-breakpoint-down(md) {
        flex-direction: column;
    }
}

.digital-img {
    @include media-breakpoint-down(lg) {
        margin: 3.5rem 0;
    }
}