.header-banner {
    background: $yellow;
    //padding: 1rem 5rem;
    .header-banner-inner {
        padding: 1rem 0;

    }
    @include media-breakpoint-down(lg) {
        .header-banner-inner {
            max-width: 100%;
            padding: 1rem 2rem;
            margin: 0;
        }
     
        
    }
    span {
        margin: 0 1rem;
    }
    a, p {
        @include label-2-medium;
    }
    a, p, span {
        color: $black;
    }
    @include media-breakpoint-down(md) {
        display: none;
    }
}
.navbar-main {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    @include media-breakpoint-down(md) {
        padding: 1rem 1.5rem;
    }
    border-bottom: 1px solid $line-grey;
    @include media-breakpoint-between(md, lg) {
        .container {
            max-width: 100%;
            margin: 0 1.5rem;
        }
    }
    @include media-breakpoint-down(md) {
        .container {
            margin: 0;
            max-width: 100%;
        }
    }
}
.offcanvas {
    padding: 5rem 5rem 0;
    width: 100%;
    max-width: 41.5rem;
    @include media-breakpoint-down(lg) {
        padding: 1.5rem;
        max-width: 26.75rem;
    }
}

.offcanvas-body {
    p {
         border-bottom: 1px solid $line-grey;
         padding: 1.5rem 0;
    }
}

.menu-item {
    font-family: 'Krona One';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.25rem;
    color: $black;
    display: block;
    padding: 1.5rem 0;
    border-bottom: 1px solid $line-grey;
}

.accordion-button::after {
    margin-left: 1.5rem;
}
.accordion-item {
    border: none;

}
.header-content {
    @include media-breakpoint-down(md) {
        display: none;
    }
}
.navbar-brand img {
    @include media-breakpoint-down(md) {
        width: 7.375rem;
        height: 1.688rem;
    }
}

.accordion-button:not(.collapsed) {
    color: $black;
    background-color: $white;
    box-shadow: none;
    border-bottom: none;
}
.accordion-button {
    padding: 1.5rem 0 0;
    box-shadow: none;
    span {
        font-family: 'Krona One';
font-style: normal;
font-weight: 400;
font-size: 1.5rem;
line-height: 2.25rem;
&:hover {
    color: $primary;
}
    }
    border-bottom: 1px solid $line-grey;
    padding-bottom: 1.5rem;
}
.accordion-body {
    padding: 1.5rem 0 2rem;
    border-bottom: 1px solid $line-grey;
       a {
    @include med-20;
    color: $black;
    &:not(:last-child) {
        margin-bottom: 1.125rem;
    }
    &:hover {
        color: $primary;
    }
   }
}
.lang-block {
    .lang-text {
        color: $black;
    }
    .lang-text.active {
            color: $primary;
            font-weight: 500;
        
    }
}