.login-container {
    padding: 3.5rem 0 28.5rem;
    @include media-breakpoint-down(md) {
        max-width: 100%;
        padding: 3.5rem 1.5rem 11.75rem;
        margin: 0;
    }
    .login-title-sub {
        color: $black;
        margin: 1rem 0 2.5rem;
    }
    .row {
        .col-lg-4 {
            margin-bottom: 1.5rem;
        }
    }
}

.form-control {
    border: none;
    border-bottom: 1px solid $black;
    border-radius: 0;
    padding: 0 2rem 0.5rem 0px;
    color: $black;
}

.input-with-icon {
    min-width: 50%;
    position: relative;
    img {
        position: absolute;
        right: 0;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
    }
}
.login-card {
    background: $background;
border-radius: 2px 3.5rem;
padding: 1.5rem;
height: 100%;
h3 {
    font-family: Krona One, sans-serif;
    font-size: 1.5rem;
    line-height: 2.25rem;
    margin: 0 0 1.5rem;
}

}