.hero-text {
    @include media-breakpoint-up(lg) {
           max-width: 39.25rem;
           position: absolute;
           top: 50%;
           transform: translateY(-50%);
        }
    p {
        @include med-20;
        color: $body;
        margin-bottom: 2rem;
    }
    h1 {
        margin-bottom: 1rem;
    }

    @include media-breakpoint-down(xxl) {
        h1 {
            font-size: 2.5rem;
            line-height: 3.75rem;
        }
    }

    @include media-breakpoint-between(sm, lg) {
        h1 {
            font-size: 2.5rem;
            line-height: 3.75rem;
        }
       text-align: center;
    }

}
.hero-section {
    padding: 4rem 0 5.75rem;
    @include media-breakpoint-down(lg) {
        padding: 0 1.5rem 8.875rem;
        //margin: 0 1.5rem;
        .col-lg-6 {
            padding: 0;
        }
    }
}
.offer-section {
    padding: 7rem 0 6rem;
    @include media-breakpoint-down(lg) {
        padding: 3.5rem 1.5rem 1.5rem;
    }
    h2 {
        margin-bottom: 3.5rem;
    }
}
.offer-inner-section {
    width: 100%;
    //max-width: 62rem;
    //margin: 3.5rem auto 0;
    //padding-top: 2.125rem;
    p {
        color: $black;
    }
    h3 {
        font-size: 1rem;
        line-height: 1.5rem;
        margin: 1rem 0 0;
    }
    h6 sub {
        font-size: 1rem;
        line-height: 1.5rem;
    }
    // @include media-breakpoint-up(lg) {
    //       .col-lg-6:first-child {
    //     padding-right: 6rem;
    //         border-right: 1px solid $line-grey;
    //     }
    // .col-lg-6:last-child {
    //     padding-left: 6rem;
    // }
    //}
  
    @include media-breakpoint-down(lg) {
        padding-top: 0;
        // .col-lg-6:first-child {
        //     border-bottom: 1px solid $line-grey;
        // }
        // .col-lg-6:last-child {
        //     padding-top: 4.5rem;
        // }
        button {
            margin-bottom: 3.5rem;
        }
    }
    @include media-breakpoint-down(md) {
        .col-lg-4 {
            padding-right: 0;
            padding-left: 0;
        }
    }
    p {
        @include med-32;
        margin-bottom: 0.5rem;
    }
}

.swiper-clients {
    margin: 0 5.875rem 0 4.125rem;
    padding: 0.75rem 0;
    @include media-breakpoint-down(lg) {
        margin: 0;

    }
  }

  .stories-swiper {
    padding: 0.75rem 0;
    margin: 0;
  }

  .swiper-stories .swiper-wrapper .swiper-slide {
    height: auto;
    cursor: pointer;
  }
  .clients-container {
    padding: 3.5rem 4.125rem 6.5rem 5.875rem;
    @include media-breakpoint-down(lg) {
        padding: 3.5rem 1.5rem 6.5rem;
        
    }
  }

  .success-container {
    padding: 7rem 0 6.5rem;

    @include media-breakpoint-down(lg) {
        h2 {
            font-size: 2.5rem;
            line-height: 3.75rem;
        }
        padding: 6.5rem 1.5rem 3rem;
        margin: 0;
        max-width: 100%;
    
    }
  
  }

.inner-container {
    padding: 7rem 0 6.5rem;

    @include media-breakpoint-down(lg) {
        h2 {
            font-size: 2.5rem;
            line-height: 3.75rem;
        }
        padding: 6.5rem 1.5rem;
        margin: 0;
        max-width: 100%;
    
    }
}

  .success-btn-image {
    transform: rotate(-90deg);
    filter: brightness(0) saturate(100%) invert(23%) sepia(59%) saturate(2229%) hue-rotate(240deg) brightness(91%) contrast(106%);
  }

  .clients-banner {
    padding: 6.5rem 0 2.5rem 5rem;
    background: $background;
    @include media-breakpoint-down(lg) {
        padding: 6.5rem 1.5rem 2.5rem;
    }
  }
  .success-tag {
    background: $yellow;
    padding: 0.375rem 1rem;
    color: $black;
    margin: 0 0.5rem 1rem;
  }
  .success-tag-outer {
    padding: 0 2rem 1rem;
    margin: 0 -0.5rem;
    
  }

  .success-col{
    justify-content: space-between;
    cursor: pointer;
    border: 1px solid $primary;
border-radius: 2px;
width: 100%;
height: 100%;
margin-right: 1.5rem;
  }
  .success-logo {
    margin: 2rem;
    display: block;
    max-width: 100%;
    //height: 3rem;
  }
  .success-content {
    margin: 0 2rem 2rem;
    p {
        color: $black;
    }
    span {
        font-size: 0.875rem;
        color: $black;
        line-height: 1.313rem;
    }
  }

  .consult-container {
    padding: 6.5rem 0;
    background: $background;
    p {
        font-weight: 500;
        color: $body;
        padding-top: 1rem;
    }
    @include media-breakpoint-down(lg) {
        padding: 6.5rem 1.5rem;
        h2 {
            font-size: 2.5rem;
            margin-bottom: 1rem;
        }
        p {
            margin-bottom: 3.5rem;
        }
    }
  }
  .rounded-top-img {
    border-radius: 0px 6rem 0px 0px;
  }
.main-image {
    //display: block;
    //margin-left: auto;
    max-width: 100%;
    height: auto;
    @include media-breakpoint-down(lg) {
        display: none;
    }
}
.main-image-mobile {
    display: none;
    @include media-breakpoint-between(sm, lg) {
        display: block;
        padding: 6.5rem 0 4rem;
        margin: 0 auto;
    }
    @include media-breakpoint-down(sm) {
        display: block;
        padding: 6.5rem 0 4rem;
    }
}
.consult-inner-block {
    //  background: $white;
    padding: 0 1.5rem 0 0;
    max-width: 54.25rem;
    @include media-breakpoint-down(lg) {
         padding: 0;
         margin-bottom: 1.5rem;
    }
   
}
.consult-inner-text {
    padding: 1.5rem;
    background: $white;
    h3 {
        @include med-24;
        color: $black;
    }
    p {
        color: $body;
        margin-bottom: 2rem;
    }
    // @include media-breakpoint-down(lg) {
    //     margin-bottom: 2rem;
    // }
    a {
        font-weight: 500;
    }
}
.partners-banner {
    padding: 6.5rem 0 2.5rem;
    
    h2 {
        margin-bottom: 1.5rem;
    }
    @include media-breakpoint-down(lg) {
        padding: 6.5rem 1.5rem 2.5rem;
        h2 {
            font-size: 2.5rem;
            margin-bottom: 0.5rem;
        }
    }
}
.partner-banner-inner {
    max-width: 39.25rem;
}

.img-border-start-end {
    border-radius: 6rem 2px;
}
.blog-icon {
    position: absolute;
    top: 50%;
    left: 50%;
}

  .blog-news {
    span {
        color: $primary;
        text-transform: uppercase;
    }
    
}

.blog-success {
    span {
        color: $success-green;
        text-transform: uppercase;
    }
    svg {
        path {
            fill: $success-green;
        }
    }
}

.blog-important {
    span {
        color: $error-red;
        text-transform: uppercase;
    }
    svg {
        path {
            fill: $error-red;
        }
    }
    
}

.blog-content-outer {
    @include media-breakpoint-down(lg) {
        &:not(:last-child) {
            margin-bottom: 3.5rem;
        }
    }
    p {
        font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.313rem;
    color: $body;
            margin: 1.5rem 0 0.5rem;
        }
        h3 {
            font-size: 1.5rem;
    line-height: 2.25rem;
    
    color: $black;
    font-weight: 500;
        }
        span {
            font-weight: 500;
            font-size: 0.75rem;
            line-height: 1.125rem;
        }

            .blog-icon{
                transition: all .3s ease-in;
                opacity: 0;
            }
            &:hover {
                .blog-icon {
                    left: 95%;
                    top:95%;
                    transform: rotate(-360deg);
                    opacity: 1;
                }
            } 
          
}
.blog-content {
    max-width: 35.625rem;
    @include media-breakpoint-down(lg) {
        max-width: 100%;
    }
}

  .swiper-stories {
    margin-top: 3.5rem;
    @include media-breakpoint-down(lg) {
        margin-top: 2.25rem;
    }
  }
  .blog-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    @include media-breakpoint-down(lg) {
        flex-direction: column;
    }
    @include media-breakpoint-up(lg) {
        align-items: center;
    }
    a {
        font-weight: 500;
    }
    margin-bottom: 3.5rem;
  }
  .success-header {
      display: flex;
    justify-content: space-between;
    flex-direction: row;
    @include media-breakpoint-down(lg) {
        flex-direction: column;
    }
     @include media-breakpoint-up(lg) {
        align-items: center;
    }
    a {
        font-weight: 500;
    }
  }
  .swiper-pagination-bullet-active {
    background: $primary;
  }
  .swiper-stories {
 .swiper-pagination {
    @include media-breakpoint-down(sm) {
        display: none;
    }
  }
  }
 
  .consult-text-container {
    max-width: 39.25rem;
    margin-bottom: 3.5rem;
    h2, p {
        margin: 0;
    }
    p {
        padding-top: 1.5rem;
        font-weight: 500;
        @include media-breakpoint-down(lg) {
            padding-top: 1rem;
        }
    }
  }

  .consult-bg {
    background: $white;
    border-radius: 0px 6rem 0px 0px;
  }
  .swiper-pagination {
        bottom: 0;
  }
  .swiper-stories .swiper-wrapper, .swiper-clients .swiper-wrapper {
    margin-bottom: 3.5rem;
  }

  .inner-offer-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include media-breakpoint-up(xxl) {
        max-width: 400px;
    }
  }