.footer-container {
    p {
        color: $white;
    }
    background: $black;
    padding: 5rem 5rem 1.5rem;

        color: $white;
        @include media-breakpoint-down(md) {
            padding: 5rem 1.5rem 0;
        }
  a {
    color: $white;
    @include reg-18;
    padding-bottom: 1rem;
  }
}
.footer-header-text {
    @include med-20;
}
.footer-line {
    border: 1px solid #353535;
    margin-top: 6.5rem;
    margin-bottom: 1.5rem;
}
.footer-brand-text {
    margin-top: 2.5rem;
    font-family: DM Sans, sans-serif;
font-style: normal;
font-weight: 500;
font-size: 1.25rem;
line-height: 1.5rem;
@include media-breakpoint-up(md) {
    max-width: 18.875rem;
}
@include media-breakpoint-down(md) {
    margin-bottom: 4.5rem;
}

}
.footer-icon {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(99deg) brightness(105%) contrast(104%);
}
.footer-social-icons {
    margin-top: 2.5rem;
    @include media-breakpoint-down(md) {
        margin-bottom: 3.5rem;
    }
}
@include media-breakpoint-between(md,lg) {
    .footer-container .row div {
        margin-bottom: 2.5rem;
    }
}
.footer-logo {
    cursor: pointer;
}