.careers-container {
  padding: 3.5rem 0 6.5rem;
  @include media-breakpoint-down(lg) {
    padding: 3.5rem 1.5rem 6.5rem;
    margin: 0;
    max-width: 100%;
  }
  h2 {
    margin: 0;
  }
  .job-header-description {
    margin: 1rem 0 2.5rem;
  }
}

.career-number {
  position: relative;
  padding: 0.625rem;
  text-align: center;
  margin: auto 0 0 1.5rem;
  background: $yellow;
  border-radius: 50%;

  span {
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-family: Krona One, sans-serif;
    color: $black;
  }
  @include media-breakpoint-down(lg) {
    margin: 0 0 1.5rem;
    max-width: 3.5rem;
  }
}

#job-search {
  max-width: 39.25rem;
  position: relative;
  margin-bottom: 5rem;
  button {
    background: url("../img/search_icon.svg");
    background-repeat: no-repeat;
    text-indent: -9999px;
    width: 1.5rem;
    height: 1.5rem;
    border: none;
    position: absolute;
    right: 0;
  }
  .form-control {
    margin-bottom: 1.5rem;
  }
  .form-select {
    background-image: url("../img/chevron_right.svg");
    background-repeat: no-repeat;
    background-position: right -3px center;
    background-size: 1.25rem;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $input-text;
    border: none;
    border-bottom: 1px solid $black;
    border-radius: 0;
    padding: 0.5rem 0;
    margin-bottom: 1.5rem;
  }
}
.job-search-select {
  @include media-breakpoint-up(lg) {
    & > * + * { margin-left: 1.5rem;}
  }
  
  //gap: 1.5rem;
  @include media-breakpoint-down(lg) {
    gap: 0;
  }
}
.job-description {
  div {
    align-items: center;
    margin-bottom: 1rem;
  }
  span {
    color: $body;
  }
  h3 {
    margin: 0;
    color: $black;
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-family: Krona One, sans-serif;
  }
  .separator {
    margin: 0 1rem;
  }
  img {
    transform: rotate(-90deg);
  }
  &:hover {
    span:not(.separator),
    h3 {
      color: $primary;
    }
    img {
      filter: brightness(0) saturate(100%) invert(18%) sepia(71%)
        saturate(5150%) hue-rotate(255deg) brightness(73%) contrast(87%);
    }
  }
}
.job-description-outer {
  padding-bottom: 1.5rem;
  &:not(:first-child) {
    padding-top: 1.5rem;
  }
  border-bottom: 1px solid $line-grey;
}

.career-inner-container {
  padding: 3rem 0 6.5rem;
  //overflow-x: hidden;
  h1 {
    margin: 2rem 0 1rem;
  }
  h3 {
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-family: Krona One, sans-serif;
    color: $black;
  }
  p {
    padding: 1rem 0 0;
    margin: 0;
  }
  @include media-breakpoint-down(lg) {
    margin: 0;
    padding: 3rem 1.5rem 6.5rem;
    max-width: 100%;
  }
}
.career-content {
  max-width: 52.813rem;
  .separator {
    margin: 0 1rem;
  }
  h3 {
    margin: 0;
    padding-top: 2.5rem;
  }
}
#workForm {
  max-width: 39.25rem;
  .form-control {
    //padding-top: 1.5rem;
    margin-bottom: 2.5rem;

  }
  .col-md-6 {
    &:nth-child(odd) {
      padding-left: 0;
    }
     &:nth-child(even) {
      padding-right: 0;
    }
    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }
}
#fileUploadButton {
  background: $yellow;
  border: none;
  padding: 0.25rem 0.5rem;
}
#fileName {
  border: none;
}
.upload-container {
  padding-bottom: 0.5rem;
  margin-bottom: 2.5rem;
  position: relative;
  border-bottom: 1px solid $black;
}
.upload-file {
  opacity: 0;
  position: relative;
  left: -2.5rem;
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: none;
  display: inline-block;
  cursor: pointer;
  background: $yellow;
  padding: 4px 8px;
  color: $black;
}

.load-jobs {
  margin-top: 3.5rem;
}

::-webkit-input-placeholder { /* Edge */
  color: $input-text;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $input-text;
}

::placeholder {
  color: $input-text;
}
#file-upload-txt {
  color: $input-text;
}
// .half-width-inputs {
//   display: flex;
//   //column-gap: 1.5rem;
//   @include media-breakpoint-down(md) {
//     flex-direction: column;
//   }
// }

.list-style-2 {
  li:before {
    filter: brightness(0) saturate(100%) invert(18%) sepia(98%) saturate(2044%) hue-rotate(247deg) brightness(88%) contrast(97%);
  }
  
}
.form-notes {
  padding: 1rem 0 2rem;
  span {
    font-size: 0.875rem;
    line-height: 1.313rem;
  }
}
.offer-info {
  display: flex;
  flex-direction: column;
  span {
    font-size: 0.875rem;
    line-height: 1.313rem;
  }
  p {
    font-weight: 500;
    margin: 0;
    padding: 0;
    color: $black;
  }
}