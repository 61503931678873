.offer-btn {
padding: 1rem 1.5rem;
border: 1px solid $black;
border-radius: 0.125rem;
    font-family: DM Sans, sans-serif;
    color: $black;
    font-weight: 500;
}
.offer-btn-link {
    margin: 0 2rem;
}
.btn-close {
    background: url("../img/close_icon.svg");
    opacity: 1;
}

.consult-btn {
    padding: 1rem 1.5rem;
border-radius: 0.125rem;
background: $primary;
    color: $white;
    font-weight: 500;
    line-height: 1.5rem;
    img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(99deg) brightness(105%) contrast(104%);
        transform: rotate(-90deg);
    }
     &:hover {
    color: $white;
    background: $hover-purple;
  }
}

.more-btn {
display: block;
margin: 0 auto;
width: 100%;
border-radius: 0.125rem;
padding: 0.875rem 0;
background: $primary;
    font-size: 1.125rem;
    color: $white;
    font-weight: 500;
    line-height: 1.688rem;
    img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(99deg) brightness(105%) contrast(104%);
        transform: rotate(-90deg);
    }
     &:hover {
    color: $white;
    background: $hover-purple;
  }
}
.project-start-btn {
    display: block;
margin: 0 auto;
width: 100%;
border-radius: 0.125rem;
color: $black;
padding: 0.875rem 0;
background: $white;
border: 0.125rem solid $black;
border-radius: 0.125rem;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.688rem;
&:hover {
    color: $white;
    background: $hover-purple;
  }
}

.login-btn {
    display: block;
margin: 0 auto;
border-radius: 0.125rem;
color: $black;
padding: 0.875rem 1rem;
background: $yellow;
border-radius: 0.125rem;
    font-size: 0.875rem;
    line-height: 1.313rem;
    border: 0;
}
.submit-btn {
    display: block;
    background: $primary;
border-radius: 0.125rem;
font-size: 1.125rem;
font-weight: 500;
line-height: 1.688rem;
color: $white;
width: 50%;
padding: 0.875rem 0;
@include media-breakpoint-down(md) {
    width: 100%;
}
&:hover {
    color: $white;
    background: $hover-purple;
  }
}