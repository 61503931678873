.container-404 {
    padding: 3.5rem 0 5.75rem;
    display: flex;
    justify-content: center;
    @include media-breakpoint-down(lg) {
        padding: 0;
    }
}
.background-404{
    background-image: url("../img/404_background.png");
    width: 80rem;
    height: 39.875rem;
    background-repeat: no-repeat;
    position: relative;
}
.absolute-center-404 {
    padding: 7.625rem 10.375rem;
    width: 100%;
    @include media-breakpoint-down(lg) {
        padding: 11.625rem 1.5rem 0;
    }
}
.text-404 {
    padding-top: 2.5rem;
    @include media-breakpoint-down(lg) {
        padding-top: 2rem;
        font-size: 1.5rem;
        line-height: 2.25rem;
    }
}
.partner-container {
     padding: 3.5rem 7.625rem;

    @include media-breakpoint-down(lg) {
        padding: 3.5rem 1.5rem;
    }
    img {
        height: 3rem;
        object-fit: contain;
        @include media-breakpoint-down(lg) {
            //max-height: 1.75rem;
            height: 28px;
            width: 100px;
            &:last-child {
                max-width: 3.5rem;
            }

        }
      
    }
}
.partner-logos {
    display: flex;
    justify-content: space-between;
    //flex-wrap: wrap;
    gap: 0.5rem;
    @media (max-width:360px) {
        gap: 1rem;
        flex-direction: column;
        align-items: center;
    }
}