@import "variables";
@import "mixins";
@import "node_modules/bootstrap/scss/bootstrap";
@import "swiper/swiper-bundle";
@import "nouislider/nouislider";
@import "./header.scss";
@import "./buttons.scss";
@import "./footer.scss";
@import "./404.scss";
@import "./privacy.scss";
@import "./index.scss";
@import "./aboutus.scss";
@import "./login.scss";
@import "./platform.scss";
@import "./digital.scss";
@import "./blog.scss";
@import "./careers.scss";
@import "./stories.scss";

 img {
     @extend .img-fluid;
 }
a {
    text-decoration: none;
}

h1 {
   font-family: Krona One, sans-serif;
   font-size: 4rem;
   font-weight: 400;
   line-height: 5.188rem;
   color: $black;
}
h2 {
    font-family: Krona One, sans-serif;
    font-size: 3rem;
    font-weight: 400;
    line-height: 3.75rem;
    color: $black;
    @include media-breakpoint-down(lg) {
      font-size: 2.5rem;
    }
 }

 h3 {
    font-family: DM Sans, sans-serif;
    font-size: 3rem;
    font-weight: 400;
    line-height: 3.625rem;
    color: $black;
 }
 h4 {
    font-family: Krona One, sans-serif;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 3.75rem;
    color: $black;
 }
 h5 {
    font-family: DM Sans, sans-serif;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 3rem;
    color: $black;
 }
 h6 {

        font-family: Krona One, sans-serif;
        font-size: 2rem;
        font-weight: 400;
        line-height: 3rem;
        color: $black;
 }

 body {
    font-family: DM Sans, sans-serif;
    size: 1rem;
    line-height: 1.5rem;
    color: $body;
 }
 body,html {
  overflow-x: hidden;
 }

 .full-width-background {
   background: $background;
   width: 100vw;
 }