.stories-container {
  h1 {
    margin-bottom: 2rem;
  }
  padding: 3.5rem 0 6.5rem;
  @include media-breakpoint-down(lg) {
    padding: 3.5rem 1.5rem 6.5rem;
    margin: 0;
    max-width: 100%;
  }
}
.load-more-link {
  display: flex;
  justify-content: center;
  font-weight: 500;
}
.single-story-container {
  padding: 3rem 0 3.5rem;
  h3 {
    font-size: 1.5rem;
    font-family: Krona One, sans-serif;
    line-height: 2.25rem;

    color: $black;
    margin-bottom: 1rem;
  }
  h1 {
    margin: 2rem 0 1rem;
  }

  @include media-breakpoint-down(lg) {
    margin: 0;
    padding: 3rem 1.5rem 3.5rem;
    max-width: 100%;
  }
}
.success-tags {
  display: flex;
  margin: 0 -0.5rem 0;
  flex-wrap: wrap;
  .success-tag {
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
  }
}

.story-text {
  margin-bottom: 2.5rem;
}

#successModal, #applicationModal {
  padding: 0;
  //overflow-x: hidden;
  .modal-content {
    padding: 1.5rem 1.5rem 0;
  }
  .modal-dialog {
    max-width: 46rem;
    width: 100%;
    margin: 1.75rem auto;
  }
  .modal-header {
    border: none;
    padding: 1.5rem 0 0 1.5rem;
  }
  .modal-body {
    padding: 0 1.5rem 3.5rem;
    h2 {
      margin-bottom: 1.5rem;
    }
    p {
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 1.688rem;
      padding-top: 0;
    }
    @include media-breakpoint-down(lg) {
      padding: 1.5rem 1.5rem 5rem 0;
      h2 {
        font-size: 1.5rem;
        line-height: 2.25rem;
      }
    }
  }
}
#applicationModal {
  .modal-dialog {
      max-width: 52.813rem;
        width: 100%;
  }

}

.custom-story-list {
  list-style: none;
  padding: 0;
  li::before {
    content: url("../img/list_icon_yellow.svg");
    padding-right: 1rem;
  }
  li {
    padding: 1rem 0 0;
    font-size: 1rem;
    color: $body;
    display: flex;
  }
}

.story-banner {
  padding: 3.5rem 0;
  p {
    font-weight: 500;
    font-size: 1rem;
    color: $body;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  h2 {
    color: $primary;
    text-align: center;
  }
  @include media-breakpoint-up(lg) {
    .row {
      justify-content: space-around;
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 1.5rem;
    .col-lg-4 {
      padding: 0 0 1.5rem;
    }
  }
}
.story-img {
  margin-bottom: 1.5rem;
}
.story-images-container {
  max-width: 90rem;
  padding: 0;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  content: "";
}

.single-story-inner {
  max-width: 52.813rem;
}
.story-main-logo {
  @include media-breakpoint-up(lg) {
    margin: 1.5rem 0 0;
  }
  @include media-breakpoint-down(lg) {
    margin: 1.5rem 0 0.5rem;
  }
}

.reviews-container {
  padding: 0 0 1.5rem;
  @include media-breakpoint-down(lg) {
    padding: 0 1.5rem 1.5rem;
    margin: 0;
    max-width: 100%;
  }
  p {
    margin: 1rem 0 0;
    text-align: center;
  }
  .reviewer {
    font-size: 0.875rem;
    line-height: 1.313rem;
    padding: 1rem 0 3.5rem;
    color: $input-text;
  }
}
.review-content {
  max-width: 52.813rem;
}


.swiper-button-prev, .swiper-button-next {
  font-weight: 500;
font-size: 18px;
line-height: 27px;

color: $primary;
}
.swiper-button-prev {
  img {
    transform: rotate(90deg);
    filter: brightness(0) saturate(100%) invert(18%) sepia(71%) saturate(5150%) hue-rotate(255deg) brightness(73%) contrast(87%);
  }
}

.swiper-button-next {
  img {
    transform: rotate(-90deg);
    filter: brightness(0) saturate(100%) invert(18%) sepia(71%) saturate(5150%) hue-rotate(255deg) brightness(73%) contrast(87%);
  }
}
